import React from "react";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";
import Layout from "../../layout";
import config from "../../../data/SiteConfig";

import "./Contact.css";

const markdown = `

If you have feedback, comments, questions or concerns regarding the site or any of its content please send an email to the following email: 

**joseph@firebudgets.com**

If you'd like a response to your message please leave your preferred method of contact (email or social media handle) somewhere in the message.

I look forward to hearing from you!
`

function About() {
  return (
  <Layout>
    <div className="contact-container">
      <Helmet title={`Contact | ${config.siteTitle}`} />
        <div className="contact">
          <h1>
            Have Feedback or Questions? Reach out!
          </h1>
          <ReactMarkdown source={markdown} className="about-text"/>
        </div>
    </div>
  </Layout>
  );
}

export default About;
